import { Value } from "sass";
import "slick-carousel";
import "./mp.js";

jQuery(document).ready(function ($) {


  // results filter
  // $("#results-filter").change(function () {
  //   const filterVal = $(this).val();
  //   console.log(filterVal);
  //   $(".featured-post").each(function () {
  //     const thisItem = $(this);
  //     const thisItemCategory = thisItem.attr("data-category");
  //     if (thisItemCategory.includes(filterVal)) {
  //       thisItem.show();
  //     } else {
  //       thisItem.hide();
  //     }
  //     $('#adv-news-global, .pagination').hide();
  //     if(filterVal == "") {
  //       $('#adv-news-global, .pagination').show();
  //     }
  //   });
  // });


  // const allFaqLinks = document.querySelectorAll('#faq a')
  const mj_locale = mj_data["site_locale"].toLowerCase().replace(/_/g, "-");
  const allTrpLinks = document.querySelectorAll(".feld-tp-exclude");
  const allTrpLinksAlt = document.querySelectorAll(".feld-tp-exclude-alt");
  console.log(`mj_locale: ${mj_locale}`);
  if (allTrpLinks.length > 0) {
    allTrpLinks.forEach((link) => {
      if (link.getAttribute("href")) {
        if (link.getAttribute("href").includes("wp-content")) {return;}
        const linkHref = link.getAttribute("href");
        if (!linkHref.includes("monsterjam.com")) return;
        const originalUrl = new URL(linkHref);
        const newUrl = new URL(originalUrl);

        newUrl.protocol = originalUrl.protocol;
        newUrl.hostname = originalUrl.hostname;
        newUrl.pathname = mj_locale + originalUrl.pathname;
        newUrl.search = originalUrl.search;
        newUrl.hash = originalUrl.hash;

        link.setAttribute("href", newUrl);
      }
    });
  } else {
    console.log("no .feld-tp-exclude links");
  }
  if (allTrpLinksAlt.length > 0) {
    allTrpLinksAlt.forEach((link) => {
      if (link.getAttribute("href")) {
        if (link.getAttribute("href").includes("wp-content")) {return;}
        const linkHref = link.getAttribute("href");

        if (!linkHref.includes("monsterjam.com")) return;
        if (!linkHref.includes("en-us")) return;
        
        const originalUrl = new URL(linkHref);
        const newUrl = new URL(originalUrl);

        console.log(`${originalUrl.pathname}`);
        const pathArray = originalUrl.pathname.split("/");
        console.log(`${pathArray}`);
        pathArray.shift();
        console.log(`${pathArray.shift()}`);
        originalUrl.pathname = pathArray.join("/");
        console.log(`${originalUrl.pathname}`);

        console.log(`${originalUrl}`);

        newUrl.protocol = originalUrl.protocol;
        newUrl.hostname = originalUrl.hostname;
        newUrl.pathname = mj_locale + originalUrl.pathname;
        newUrl.search = originalUrl.search;
        newUrl.hash = originalUrl.hash;

        console.log(`${newUrl}`);

        link.setAttribute("href", newUrl);
      }
    });
  } else {
    console.log("no .feld-tp-exclude-alt links");
  }

  // mobile menu
  $(".menu-item-has-children").append(
    '<button class="toggle-sub-menu mobile"><i class="fa-solid fa-plus"></i><i class="fa-solid fa-minus"></i></button>'
  );

  $(".menu-trigger").click(function () {
    $(this).toggleClass("active");
    $(".menu-container").toggleClass("active");
  });

  $(".toggle-sub-menu").click(function () {
    $(this).siblings(".sub-menu").slideToggle();
    $(this).toggleClass("active");
  });

  // video popup
  $("#videos .video").click(function (e) {
    e.preventDefault();
    const videoId = $(this).attr("data-video-id");
    $("#video-popup iframe").attr(
      "src",
      `https://www.youtube.com/embed/${videoId}?autoplay=1`
    );
    $("#video-popup").addClass("active");
  });

  $("#video-popup .close").click(function () {
    $("#video-popup").removeClass("active");
    $("#video-popup iframe").attr("src", "");
  });

  // FAQ's accordion
  $(".faq-acc button").click(function () {
    $(this).toggleClass("active");
    $(this).siblings(".content").slideToggle();
  });

  // videos
  $(".slider-videos img").click(function () {
    const targetId = $(this).attr("data-video-id");
    $("#driver-gallery .featured").attr(
      "src",
      `https://www.youtube.com/embed/${targetId}`
    );
  });

  $(".slider-videos").slick({
    accessibility: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
  });

  $(".slider-drivers").slick({
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  });

  $(".slider-trackside-top").slick({
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    adaptiveHeight: true,
    asNavFor: ".slider-trackside-bottom",
  });

  $(".slider-trackside-bottom").slick({
    accessibility: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    adaptiveHeight: true,
    asNavFor: ".slider-trackside-top",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  });

  $(".split-slider-top").slick({
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    adaptiveHeight: true,
    asNavFor: ".split-slider-bottom",
  });

  $(".split-slider-bottom").slick({
    accessibility: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    adaptiveHeight: true,
    asNavFor: ".split-slider-top",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  });

  $(".slider-truck-photos").slick({
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,    
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerMode: true,
          centerPadding: '260px',
        },
      },
    ],
  });

  $("#filter-by-letter").change(function () {
    const filterVal = $(this).val().toLowerCase();
    if (filterVal === "default") {
      $(".driver-item").addClass("active");
      return;
    }
    $(".driver-item").each(function (index) {
      const theItem = $(this).find("h3").html().charAt(0).toLowerCase();
      if (theItem === filterVal) {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });
  });

  if ($("body").hasClass("driver-template-default")) {
    const thisDriverId = $("#this-driver-id").attr("data-driver-id");
    $(".points-table").each(function (index) {
      const tableTitle = $(this).find(".title")[0].innerHTML;
      console.log(tableTitle);
      $(this)
        .find(".driver-id-container")
        .each(function (index) {
          const driverId = $(this).attr("data-driver-id");
          if (driverId === thisDriverId) {
            $(this).closest(".points-table").addClass("active");
          }
        });
    });
  }

  // Events Sort
  function eventsSortHandler(e) {
    const thisItem = e;
    const sortTerm = $(thisItem).attr("data-sort-term");
    const ticketsContainer = $(thisItem)
      .parent()
      .parent()
      .parent()
      .parent()
      .parent()
      .siblings(".felds-board-tickets");

    if ($(ticketsContainer).length > 0) {
      const allEvents = $(ticketsContainer).find(".engagement");

      function SortByDate(a, b) {
        const dateA = $(a).attr("data-date");
        const dateB = $(b).attr("data-date");
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      }

      function SortByCity(a, b) {
        const dateA = $(a).attr("data-city");
        const dateB = $(b).attr("data-city");
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      }

      $(ticketsContainer).html("");

      if (sortTerm === "city") {
        allEvents.sort(SortByCity);
      } else {
        allEvents.sort(SortByDate);
      }

      allEvents.appendTo($(ticketsContainer));
    }
  }

  $(".sort-button").click(function () {
    eventsSortHandler($(this));
    $(this).parent().find(".sort-button").removeClass("active");
    $(this).addClass("active");
  });

  // Events Search
  // $(".events-search").submit(function (e) {
  //   e.preventDefault();
  //   const thisElement = $(this);
  //   const ticketsContainer = $(thisElement)
  //     .parent()
  //     .parent()
  //     .parent()
  //     .parent()
  //     .siblings(".felds-board-tickets");
  //   const searchTerm = $(this).find("input").val().toLowerCase();
    
  //   $(ticketsContainer)
  //     .find(".engagement")
  //     .each(function (index) {
  //       const city = $(this).attr("data-city").toLowerCase();
  //       if (city.includes(searchTerm)) {
  //         $(this).addClass("active");
  //       } else {
  //         $(this).removeClass("active");          
  //       }
  //     });
  // });

  // event competitor slider
  $(".slider-competitors, .trucks-slider .flex-row").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  });

  $(".slider-competitors-fsm").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $(
    ".slider-trackside-bottom, .slider-trackside-top, .toy-gallery, .split-slider-top, .split-slider-bottom"
  ).magnificPopup({
    delegate: "a",
    type: "image",
    callbacks: {
      elementParse: function (item) {
        // Function will fire for each target element
        // "item.el" is a target DOM element (if present)
        // "item.src" is a source that you may modify
        console.log(item.el[0].className);
        if (item.el[0].className == "video") {
          (item.type = "iframe"),
            (item.iframe = {
              patterns: {
                youtube: {
                  index: "youtube.com/", // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                  id: "v=", // String that splits URL in a two parts, second part should be %id%
                  // Or null - full URL will be returned
                  // Or a function that should return %id%, for example:
                  // id: function(url) { return 'parsed id'; }

                  src: "//www.youtube.com/embed/%id%?autoplay=1", // URL that will be set as a source for iframe.
                },
                vimeo: {
                  index: "vimeo.com/",
                  id: "/",
                  src: "//player.vimeo.com/video/%id%?autoplay=1",
                },
                gmaps: {
                  index: "//maps.google.",
                  src: "%id%&output=embed",
                },
              },
            });
        } else {
          (item.type = "image"),
            (item.tLoading = "Loading image #%curr%..."),
            (item.mainClass = "mfp-img-mobile"),
            (item.image = {
              tError:
                '<a href="%url%">The image #%curr%</a> could not be loaded.',
            });
        }
      },
    },
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
    },
  });



  // event innerpage results
  const edpResults = document.querySelectorAll('#event-results .points-table');
  const currentEvent = document.querySelector('#event-results')?.getAttribute('data-show-name')?.toUpperCase() || '';
  if (edpResults?.length) {
    edpResults.forEach(table => {
      const titleElement = table.querySelector('.title');
      if (titleElement) {
        table.style.display = titleElement.textContent.includes(currentEvent) ? 'block' : 'none';
      }
    });
  }


  // popup
  setTimeout(function () {
    $("#popup").addClass("active");
  }, 1000);
  $("#popup .close").click(function () {
    $(this).closest("#popup").removeClass("active");
  });

  // GTM
  const isMobile = () => $(window).width() < 992;

  window.googletag = window.googletag || { cmd: [] };

  googletag.cmd.push(function () {
    $(".adv-container").each(function () {
      if (isMobile()) {
        googletag
          .defineSlot("/106050698/MJ", [[320, 50]], $(this).attr("id"))
          .addService(googletag.pubads());
      } else {
        googletag
          .defineSlot("/106050698/MJ", [[728, 90]], $(this).attr("id"))
          .addService(googletag.pubads());
      }
    });

    $(".news-adv").each(function () {
      googletag
        .defineSlot("/106050698/MJ", [[300, 250]], $(this).attr("id"))
        .addService(googletag.pubads());
    });

    googletag.pubads().enableSingleRequest();
    googletag.enableServices();

    $(".adv-container, .news-adv").each(function () {
      googletag.display($(this).attr("id"));
    });
  });

  $(".slick-slide.slick-cloned").find(".truck-name").removeAttr("aria-live");
  $(".slick-slide.slick-cloned").find(".truck-name").removeAttr("tabindex");
  $(".slick-slide.slick-cloned").find(".driver-name").removeAttr("aria-live");
  $(".slick-slide.slick-cloned").find(".driver-name").removeAttr("tabindex");


  // Scroll to anchor
	const urlParams = new URLSearchParams(window.location.search);
  const anchor = urlParams.get('section');
  if (anchor) {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

});
